import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {clearAllPatients, clearPatientService, loadAllPatients} from "../../actions/patients.actions";
import Loader from "react-spinners/BeatLoader";
import {Link} from "react-router-dom";
import isEmpty from "../../utils/helpers";
import NixioTable, {SelectColumnFilter} from "../Table/NixioTable";
import axiosInstance from "../../utils/axios-instance";
import {toast} from "react-toastify";
import {closeDocuments} from "../../utils/document-utils";

const SearchPatient = ({clearPatientService}) => {
    const [loadingPatients, setLadingPatients] = useState(true);
    const [patients, setPatients] = useState([]);

    useEffect(() => {
        closeDocuments();
        clearPatientService();
    }, []);

    useEffect(() => {
        setLadingPatients(true);
        const loadData = async () => {
            const serviceResp = await axiosInstance.get("/api/patient-services");
            if (!serviceResp.data.success) {
                throw new Error(serviceResp.data.errorMessage);
            }

            const services = serviceResp.data.services;
            if (!services) {
                return;
            }

            // const patientMap = new Map();
            const patientList = [];
            for (const service of services) {
                const patientResponse = await axiosInstance.get('/api/patients', {params: {serviceId: service.id}});
                if (!patientResponse.data) {
                    continue;
                }

                const patients = patientResponse.data;
                if (patients) {
                    patientList.push(...patients.map(p => ({...p, service: service})));
                }
            }

            setPatients(patientList);
        }

        loadData().catch((err) => {
            console.error(err);
            toast.error("Unable to fetch patients: " + err.message);
        }).finally(() => {
            setLadingPatients(false);
        })

    }, [])

    const getWeekInProgram = useCallback((patient = {}) => {
        if (patient.completedProgram) {
            return "Completed program"
        }

        return isEmpty(patient.weekInProgram)? "Not enrolled" : patient.weekInProgram;
    }, []);

    const columns = useMemo(() => {
        return [
            {
                Header: 'CRM Case Id',
                accessor: 'crmCases[0].crmCaseId',
                Cell: (props) => {
                    const patient = props.row.original;

                    const newTo = {
                        pathname: `/patient/${patient.crmCases[0].crmCaseId}/home`,
                    };

                    if (patient.service) {
                        newTo.search = new URLSearchParams({ps: patient.service.id}).toString();
                    }

                    return <Link to={newTo}>{patient.crmCases[0].crmCaseId}</Link>
                }
            },
            {
                Header: 'First Name',
                accessor: 'firstName',
            },
            {
                Header: 'Last Name',
                accessor: 'lastName',
            },
            {
                Header: 'Service',
                Filter: SelectColumnFilter,
                accessor: (data, idx, row) => {
                    return row.original?.service?.name;
                },
            },
            {
                Header: 'Week #',
                accessor: getWeekInProgram,
            },
            {
                Header: 'New Files',
                id: 'newFiles',
                accessor: 'newFiles',
                Cell: ({value}) => value || 'None',
            },
            {
                Header: 'New Data',
                id: 'newData',
                accessor: 'newData',
                Cell: ({value}) => value || 'None',
            },
            {
                Header: 'New tasks',
                accessor: 'newTasks',
            },
            {
                Header: 'New notes',
                accessor: 'newNotes',
            },
        ];
    }, []);

    const initialState = useMemo(() => {
        return  {
            sortBy: [
                {
                    id: 'patientId',
                    desc: false
                }
            ]
        }
    }, []);

    return (
        <div>
            {(loadingPatients)
                ?
                <div className="text-center">
                    <Loader size={10} color={"#32383e"}/>
                </div>
                :
                <>
                    <div className='row'>
                        <div className="col">
                            <NixioTable data={patients} columns={columns} initialState={initialState} className="patient-table" stickyHeader={true}/>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
    loadAllPatients,
    clearAllPatients,
    clearPatientService,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPatient);